<template>
<div v-for="item in tuiguang" :key="item" style="width: 100%;">
    <div class="biaoti" v-for="list in item.biaoti" :key="list">
          <div class="biaoti_l">{{ list.title }}</div>
          <div class="biaoti_r"><img :src="list.timg" /></div>
    </div>
    <div class="daohang" v-for="list in item.daohang" :key="list">
                <div class="daohang_1">{{ list.daohang1}}{{ list.daohang2}}<font color="#f75790">{{ list.daohang3}}</font></div>
    </div>
    <div class="yijiejue" v-for="list in item.yijiejue" :key="list">
                <div class="yijiejue_1">{{ list.yijiejue1}}</div>
                <div class="yijiejue_2">
                        <div class="yijiejue_2s">{{ list.yijiejue2}}</div>
                        <div class="yijiejue_2x">
                              <div class="yijiejue_2x1">{{ list.yijiejue3}}</div>
                              <div class="yijiejue_2x2">{{ list.yijiejue4}}</div>
                              <div class="yijiejue_2x3">{{ list.yijiejue5}}</div>
                        </div>
                </div> 
    </div>

    <div class="louzhu1" v-for="list in item.louzhu1" :key="list">
                <div class="louzhu1_touxiang"><img :src="list.touxiang" /></div>
                <div class="louzhu1_nicheng">{{ list.nicheng}}</div>
                <div class="louzhu1_dengji">{{ list.dengji}}</div>
                <div class="louzhu1_guanzhu">{{ list.guanzhu}}</div>
                 
    </div>
    <div class="clear"></div>
    <div class="louzhu2" v-for="list in item.louzhu2" :key="list">
                <div class="louzhu2_liulanliang">{{ list.liulanliang}}</div>
                <div class="louzhu2_riqi">{{ list.riqi}}</div>
                <div class="louzhu2_kangfu">{{ list.kangfu}}</div>
    </div>
    <div class="clear"></div>
    <div class="miaoshu" v-for="list in item.miaoshu" :key="list">
                <div class="miaoshu1">{{ list.miaoshu1}}</div><div class="clear"></div>
                <div class="miaoshu2"><img :src="list.miaoshu2" /></div><div class="clear"></div>
                <div class="miaoshu3">{{ list.miaoshu3}}</div><div class="clear"></div>
                <div class="miaoshu4">{{ list.miaoshu4}}<font color="#f75790">{{ list.miaoshu5}}</font></div><div class="clear"></div>
                <div class="miaoshu5">{{ list.miaoshu6}}</div><div class="clear"></div>
                
    </div>
    <div class="rmpl" v-for="list in item.rmpl" :key="list">
                <div class="rmpl1">{{ list.rmpl1}}</div>                 
    </div>

    <div class="pl1" v-for="list in item.pinglun1" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>
                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>
                </div>  
                <div class="clear"></div>           
    </div>
    <div class="rmpl" v-for="list in item.rmpl" :key="list">
                <div class="rmpl1">{{ list.rmpl1}}</div>                 
    </div>


    <div class="pl1" v-for="list in item.pinglun2" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3"><a :href="list.huida4">{{ list.huida4}}</a> </div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>
      
       <div class="pl1" v-for="list in item.pinglun3" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida3" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>


       <div class="pl1" v-for="list in item.pinglun4" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida4" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>


       <div class="pl1" v-for="list in item.pinglun5" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida5" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>
       <div class="pl1" v-for="list in item.pinglun6" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida6" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>

       <div class="pl1" v-for="list in item.pinglun7" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida7" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>
       <div class="pl1" v-for="list in item.pinglun8" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                       

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>

       <div class="pl1" v-for="list in item.pinglun9" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida9" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>


       <div class="pl1" v-for="list in item.pinglun10" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida10" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>


       <div class="pl1" v-for="list in item.pinglun11" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida11" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">{{ list.huida4}}</div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>



       <div class="pl1" v-for="list in item.pinglun12" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida12" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3">   <a :href="list.huida4">{{ list.huida4}}</a>   </div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>


       <div class="pl1" v-for="list in item.pinglun13" :key="list">
                <div class="pl1_touxiang"><img :src="list.touxiang" /></div>    
                <div class="pl1_r">
                        <div class="pl1_r_1">
                                <div class="pl1_r_left">
                                       <div class="pl1_r_xingming">{{ list.xingming}}<font color="#f75790">{{ list.dengji}}</font></div>
                                       <div class="pl1_r_zhicheng">{{ list.zhicheng}}</div>
                                </div>
                                <div class="pl1_r_right">{{ list.liulanliang}}</div>                        </div>
                        <div class="clear"></div>
                        <div class="pl1_r_2"><div class="pl1_r_neirong">{{ list.neirong}}</div></div>
                        <div class="clear"></div>
                        <div class="pl1_r_4">
                              <img :src="list.pic" />
                        </div>
                        <div class="huida" v-for="list in item.huida13" :key="list">
                               <div class="huida_1">
                                      <div class="huida_l">{{ list.huida1}}</div>
                                      <div class="huida_r">{{ list.huida2}}</div>
                                </div>
                                <div class="clear"></div>
                                <div class="huida_2">{{ list.huida3}}</div>
                                <div class="huida_3" style="color: #f75790;"><span class="zaax-wxh">{{ list.huida4}}</span></div>
                                <div class="clear"></div>
                         </div>

                        <div class="clear"></div>
                        <div class="pl1_r_3">
                              <div class="pl1_r_riqi">{{ list.riqi}}</div>
                              <div class="pl1_r_louceng">{{ list.louceng}}</div>
                        </div>    
                </div>  
                <div class="clear"></div> 
       </div>
      <div class="yinshi" v-for="list in item.yinshi" :key="list">
        {{ list.yinshi1}}
      </div>

      <div class="xiangguanhuida" >
             <div class="xiangguanhuida_1" v-for="list in item.xiangguanhuida" :key="list">
                    <div class="xiangguanhuida_l"> {{ list.xiangguanhuida1}}</div>
                    <div class="xiangguanhuida_r">{{ list.xiangguanhuida2}}</div> 
             </div>   
             
             <div class="liebiao" v-for="list in item.liebiao" :key="list">
                    <div class="liebiao_l"> {{ list.liebiao1}}</div>
                    <div class="liebiao_r">{{ list.liebiao2}}</div> 
             </div> 
             <div class="clear"></div>
             <div class="gengduo" v-for="list in item.gengduo" :key="list">
                     {{ list.gengduo1}} 
             </div> 
             
             
      </div>
 
 
 
 
</div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad18.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 